'use client'

import { BodyObjectView, key } from 'utility/utility'
import { ComponentBodyPhotoGalleryFragment } from 'generated/graphql'
import { Markdown } from 'components/shared/Markdown/Markdown'
import { SectionHeader } from 'components/shared/SectionHeader/SectionHeader'
import { Theme } from 'content-types/Theme/Theme'
import styles from './ComponentBodyPhotoGallery.module.scss'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import 'react-photo-view/dist/react-photo-view.css';

export const ComponentBodyPhotoGallery: BodyObjectView<ComponentBodyPhotoGalleryFragment> =
    ({
        images,
        header,
        siteContext,
        articleContext,
        pageContext,
        galleryHeaderTextAlign,
        theme,
        ...object
    }) => {

        return (
            <section
                id={key(object)}
                data-align={galleryHeaderTextAlign}
                style={ Theme(theme?.data?.attributes) }
            >
                {
                    header === null || header === '' ? <></> :
                        <SectionHeader className={styles.Header}>
                            <Markdown
                                siteContext={siteContext}
                                pageContext={pageContext}
                                articleContext={articleContext}
                            >
                                {header}
                            </Markdown>
                        </SectionHeader>
                }
                <section className={styles.Images}>
                    <PhotoProvider>
                        <div className={styles.ImageGrid}>
                            {images.map((image, index) => (
                            <PhotoView key={index} src={image.image.data?.attributes?.url}>
                                <img className={styles.Image} src={image.image.data?.attributes?.url} alt="" />
                            </PhotoView>
                            ))}
                        </div>
                    </PhotoProvider>
                </section>
            </section>
        )

    }

