'use client'

import { useState, useEffect, Children } from 'react'
import { BodyObjectView } from 'utility/utility'

import styles from './ComponentBodyContentBlockList.module.scss'
import RightArrow from 'public/icons/arrow-right-thin.svg'

export const Slideshow: BodyObjectView<{
    blocks?: React.ReactNode[],
    displayTime: number | null,
    showMultipleTestimonials?: boolean
}> =
    ({
        blocks = [],
        displayTime,
        showMultipleTestimonials
    }) => {

        const [autoCycle, setAutoCycle] = useState(1)
        const [[page], setPage] = useState([0, 0])

       const childrenCount = Children.count(blocks); 

       showMultipleTestimonials = showMultipleTestimonials ?? false; // set default value to false if undefined    
       const visibleBlocksCount:number = showMultipleTestimonials ? 3 : 1; // set columns 3 multiple, 1 = singular

        // set pagination
        let paginate = (newDirection: number): void =>  setPage([page + newDirection, newDirection])

        // set pagination if columns >1
        if( childrenCount > 1 ) {
            paginate = (newDirection: number): void =>  setPage([(page + newDirection + childrenCount) % childrenCount, newDirection]);
        }
        
        // create block index from passed through blocks ( data objects )
        const blockIndex = ( ( page % Children.count(blocks) ) + Children.count(blocks) ) % Children.count(blocks)

        // create array of visible blocks
        const getVisibleBlocks = (): React.ReactNode[] => {
            const visibleBlocks = []; 
        
            for (let i = 0; i < visibleBlocksCount; i++) {
                const blockIndex = (page + i) % childrenCount; 
                visibleBlocks.push(blocks[blockIndex]);
            }
            return visibleBlocks; 
        };

        useEffect( () => {
            const timer = setTimeout( () => {
                if ( autoCycle !== 0 ) {
                    paginate(autoCycle)
                }
            }, ( displayTime ?? 5 ) * 1000 )
            return (): void => clearTimeout(timer)
        })
    
        const moveLeft = (): void => {
            setAutoCycle(0)
            paginate(-1)
        }

        const moveRight = (): void => {
            setAutoCycle(0)
            paginate(1)
        }


            if ( !showMultipleTestimonials ) {

                return (           
                    <div className={styles.Slideshow}>
                        <ul key='blocks'>                    
                            {
                            Children.map(blocks, (block, index) =>
                                <li
                                    key={index}
                                    data-current={ index === blockIndex }
                                >
                                    {block}
                                </li>
                            )}    
                        </ul>
                        <button
                            className={styles.ControlLeft}
                            onClick={moveLeft}
                            title='Move left'>
                            <RightArrow/>
                        </button>
                        <button
                            className={styles.ControlRight}
                            onClick={moveRight}
                            title='Move right'>
                            <RightArrow/>
                        </button>
                    </div>
                 ) 
            }

            if (showMultipleTestimonials ) {

                return (           
                    <div key='blocks'
                         className={styles.SlideShowMultiple}>   
                        <button
                            className={styles.ControlLeft}
                            onClick={moveLeft}
                            title='Move left'>
                            <RightArrow/>
                        </button>
                        {
                            getVisibleBlocks().map((block, index) => (
                                <div className={styles.SlideShowMultipleInner}
                                    key={index}
                                >
                                    {block}
                                </div>
                            ))
                        }
                        <button
                            className={styles.ControlRight}
                            onClick={moveRight}
                            title='Move right'>
                            <RightArrow/>
                        </button>
                    </div>  
                )                
            }      
             
    }